import React, { useEffect, useState } from 'react';
import { SERVER_URL } from '../config.js';
import { ToastContainer, toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate, useParams } from 'react-router-dom';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography'
import EditIcon from '@mui/icons-material/Edit';
import TextField from '@mui/material/TextField';
import DateFnsUtils from '@date-io/date-fns';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import Grid from '@mui/material/Grid';
import dayjs from 'dayjs'; 
import 'dayjs/locale/en-gb';
import utc from 'dayjs/plugin/utc';
import { setHours, setMinutes } from 'date-fns';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import enGB from 'date-fns/locale/en-GB';
import Button from '@mui/material/Button'; 
import { FileDrop } from 'react-file-drop';
import { common } from '@mui/material/colors';

let emptySensor = {
    serial : '',
    code : '',
    ruta: '',
    updatedAt: '',
    batteryChart: 1,
    pic: ''
}

export default function SensorList() {

    const { id } = useParams();
    const [sensor, setSensor] = React.useState(emptySensor);
    const [serialErrorMessage, setSerialErrorMessage] = React.useState("");
    const [isAdmin, setIsAdmin] = React.useState(false);
    const [inputElement, setInputElement] = React.useState();

    dayjs.extend(utc);

    useEffect(() => {
        fetchSensor();
        fetchUser();
    },[]);

    const fetchSensor = () => {
        const jwtToken = sessionStorage.getItem("jwt");
    
        //Use same endpoint as plot because are same params
        fetch(SERVER_URL + `sensors/${id}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': jwtToken
    
          }
        })
        .then((response) => response.json())
        .then((responseData => {
            console.log(responseData);
            setSensor(responseData);
            console.log("Fetching image");
            console.log(responseData.picture);
            if(responseData.picture != null){
                console.log("is not null");
                fetchImage();
            }
        }))
        .catch(err => console.error(err));
    } 

    const fetchUser = () => {
        const jwtToken = sessionStorage.getItem("jwt");
    
        //Use same endpoint as plot because are same params
        fetch(SERVER_URL + `loggedinuser`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': jwtToken
    
          }
        })
        .then((response) => response.json())
        .then((responseData => {
            console.log(responseData);
            setIsAdmin(responseData.admin);
        }))
        .catch(err => console.error(err));
    } 

    const submitSensor = () => {
        const jwtToken = sessionStorage.getItem("jwt");
    
        //Use same endpoint as plot because are same params
        fetch(SERVER_URL + `sensors/${id}`, {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': jwtToken
    
          },
          body: JSON.stringify(sensor)
        })
        .then((response) => response.json())
        .then((responseData => {
            toast.success("Cambios guardados", { position: toast.POSITION.BOTTOM_LEFT });
        }))
        .catch(err => {
            alert("ha ocurrido un error");
            console.error(err);
        });
    } 

    const postFiles = (formData) => {
        toast("Subiendo imagen",{position: toast.POSITION.BOTTOM_LEFT, autoClose: false});
        const jwtToken = sessionStorage.getItem("jwt");
        fetch(SERVER_URL + `sensors/${id}/picture`, {
            method: 'POST',
            headers:{
                'Authorization' : jwtToken
            },
            body: formData
        }).then(res => {
            toast.dismiss();
            if(res.ok) {
                alert("Imagen actualizada")
                fetchImage();
            }else{
                switch(res.status){
                    case 400:
                        alert("Unsupported Excel Format. Try to open and save the file with MS Excel.")
                        break;
                    case 415:
                        alert("Bad Excel Data Format. Unexpected Data.");
                        break;
                    case 404:
                        alert("Error: Compound not found. Check all products referenced in the file are present in DB.");
                        break;
                    case 507:
                        alert("Storage error. System could be full. Contact Administrator.");
                        break;    
                    case 500:
                        alert("Internal Server Error. Status code 500. Contact Administrator.");
                        break;
                    default:
                        alert("Unknown error");
                        break;
                }
            }
        }).catch(err => {
            alert("Query error");
        });
    }

    const fetchImage = () => {
        const jwtToken = sessionStorage.getItem("jwt");
        
        fetch(SERVER_URL + `sensors/${id}/picture`,
            {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': jwtToken
                },
            })
            .then((response) => {
                if(response.ok){
                    return response.blob()
                }
                throw Error(response.statusText);
            })
            .then((blob) => {

                console.log("image retrieved");
                document.getElementById("plotDiv").innerHTML = '';
                // 2. Create blob link to download
                const url = window.URL.createObjectURL(new Blob([blob]));
                const img = document.createElement('img');
                img.setAttribute("src", url);
                //img.setAttribute("height", "500");
                img.setAttribute("width", "600");
                img.setAttribute("alt", "image");
                
                // 3. Append to html page
                document.getElementById("plotDiv").appendChild(img);
               
            })
        .catch (err => {
            console.error(err);
            toast.error("Error: "+ err.message);
        });
    }

    const onFileChangeHandler = (e) => {
        e.preventDefault();
        const formData = new FormData();
        for(let i = 0; i< e.target.files.length; i++) {
            formData.append('files', e.target.files[i])
        }
        postFiles(formData);  
    };

    /* This is called from onDrop event from FileDrop zone*/
    const onFileDrop = (files, event) => {
        event.preventDefault();
        const formData = new FormData();
        for(let i = 0; i<files.length; i++) {
            if(this.checkImageExtension(files[i].name)){
                formData.append('files', files[i])
            }
        }  
        postFiles(formData);
    }

    const selectFiles = (e) => {
        e.preventDefault();
        inputElement.click();
    }


    const checkImageExtension = (filename) => {
        let ext = filename.split('.').pop();
        if(ext === 'xlsx' || ext === 'XLSX' ){
                return true;
        }
        return false;
    }

    return(

        <>
        <h1>Opciones de sensor: {sensor.ruta}</h1>
        <h2>Serial: {sensor.serial}</h2>
        <Grid container direction='column'>
            <Grid item>
                <TextField
                    autoFocus
                    margin="dense"
                    id="serial"
                    label="Serial"
                    type="text"
                    value={sensor.serial}
                    variant="standard"
                    onChange={(event) => {
                        setSensor({
                            ...sensor,
                            serial: event.target.value
                        });
                    }}
                />
                <div className="stringError">{serialErrorMessage}</div><br />
            </Grid>
            <Grid item>
                <TextField
                    autoFocus
                    margin="dense"
                    id="code"
                    label="Código"
                    type="text"
                    value={sensor.code}
                    variant="standard"
                    style={{marginBottom: 20}}
                    onChange={(event) => {
                        setSensor({
                            ...sensor,  
                            code : event.target.value
                        });
                    }}
                />
            </Grid>
            <Grid item>
                <TextField
                    autoFocus
                    margin="dense"
                    id="ruta"
                    label="Nombre de ruta"
                    type="text"
                    value={sensor.ruta}
                    variant="standard"
                    style={{marginBottom: 20}}
                    onChange={(event) => {
                        setSensor({
                            ...sensor,  
                            ruta : event.target.value
                        });
                    }}
                />
            </Grid>
            <Grid item>
                <Typography>lat</Typography>
                <TextField
                    autoFocus
                    margin="dense"
                    id="x"
                    label=""
                    type="text"
                    value={sensor.locationX}
                    variant="standard"
                    onChange={(event) => {
                        setSensor({
                            ...sensor,
                            locationX : event.target.value
                        });
                    }}
                />
            </Grid>
            <Grid item>
                <Typography>lon</Typography>
                <TextField
                    autoFocus
                    margin="dense"
                    id="y"
                    label=""
                    type="text"
                    value={sensor.locationY}
                    variant="standard"
                    onChange={(event) => {
                        setSensor({
                            ...sensor,
                            locationY : event.target.value
                        });
                }}/>
            </Grid>
                {isAdmin &&
                    <>
                    <Grid item style={{marginTop:30}}>
                        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale='en-gb'>
                            <DatePicker
                                label="Revisión"
                                value={dayjs(sensor.lastReviewed) ?? dayjs()}
                                onChange={(newValue) => {
                                    const dateWithDefaultTime = newValue.add(12,'hour');
                                    setSensor({
                                        ...sensor,
                                        lastReviewed : dateWithDefaultTime
                                    });
                                }}
                            />
                        </LocalizationProvider>
                    </Grid>
                    <Grid item style={{marginTop:30}}>
                        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale='en-gb'>
                            <DatePicker
                                label="Instalación"
                                value={dayjs(sensor.installDate) ?? dayjs()}
                                onChange={(newValue) => {
                                    const dateWithDefaultTime = newValue.add(12,'hour');
                                    setSensor({
                                        ...sensor,
                                        installDate : dateWithDefaultTime
                                    });
                                }}
                            />
                        </LocalizationProvider>
                    </Grid>
                    <Grid item>
                        <TextField
                            autoFocus
                            margin="dense"
                            id="y"
                            label="Compañia"
                            type="text"
                            value={sensor.company}
                            variant="standard"
                            onChange={(event) => {
                                setSensor({
                                    ...sensor,
                                    company : event.target.value
                                });
                        }}/>
                    </Grid>
                    <Grid item>
                        <TextField
                            autoFocus
                            margin="dense"
                            id="y"
                            label="SIM"
                            type="text"
                            value={sensor.sim}
                            variant="standard"
                            onChange={(event) => {
                                setSensor({
                                    ...sensor,
                                    sim : event.target.value
                                });
                        }}/> 
                    </Grid>
                    <Grid item>
                        <div id="plotDiv" style={{marginLeft : 10}}></div>
                        <FileDrop style={{width:'100%'}}
                            onDrop={(files, event) => this.onFileDrop(files, event)}>   
                            <input type="file" name="fileInput" style={{display : "none"}} 
                                ref={input => setInputElement(input)} 
                                onChange={onFileChangeHandler}
                                />
                            <Grid container direction="row" spacing={3} alignItems="center" justify="center" style={{marginTop:20}}>
                                <Grid item>
                                    <Button variant="contained" color="primary" onClick={selectFiles}>Selecciona una imagen</Button>
                                </Grid>
                                <Grid item style={{width : '100%', borderWidth : 1, borderColor : common }}>
                                    <h3>Puedes arrastrar una imagen aqui</h3>
                                </Grid>
                            </Grid>
                            <Grid container spacing={3} style={{marginTop : 20, minHeight : 20, borderWidth : '1px', borderColor : "black"}}>
                                {/**Name of file goes here */}
                            </Grid>
                        </FileDrop>
                    </Grid>  
                    </>
                }
        </Grid>
        <IconButton color="primary" onClick={submitSensor} Style={{marginTop:30}}>
            Guardar
        </IconButton>
        </>
    )
}