import { createTheme } from '@mui/material/styles';


export default createTheme({
    palette: {
        primary: {
            light: '#61ce35',
            main: '#69B64C',
            dark: '#6baf50',
            contrastText: '#fff',
          },
        secondary: {
            light: '#73d3c3',
            main: '#50c9b5',
            dark: '#388c7e',
            contrastText: '#fff',
          },
        textPrimary: '#50c9b5',
        healthy :"#7fe57a",
        dead :"#d20049",
        affected : "#ff8c00",
        AffectedSB : "#fff200",
        discarded : "#3463df",
        unselected : "#ededed"
    },
    
});