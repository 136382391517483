import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import AdbIcon from '@mui/icons-material/Adb';
import logoMT from './assets/logo_m_t.png'
import PowerSettingsNewIcon from '@mui/icons-material/PowerSettingsNew';
import { useNavigate } from 'react-router-dom';



export default function ResponsiveAppBar() {

  const navigate = useNavigate();
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const [loggedIn, setLoggedIn] = React.useState(false);

  React.useEffect(() =>{
    if(sessionStorage.getItem("jwt")){
        setLoggedIn(true);
    }else{
        setLoggedIn(false);
    }
  });

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const goToSensors = () => {
    handleCloseNavMenu();
    navigate(`/sensors`);
  }

  const goToMap = () => {
    handleCloseNavMenu();
    navigate(`/`);
  }

  const goToOptions = () => {
    handleCloseNavMenu();
    navigate(`/Options`);
  }

  const logout = () => {
    sessionStorage.removeItem("jwt");
    navigate('/login');
  }

  const drawAppBar = () => {
      if(loggedIn){
          return(
            <AppBar position="static">
                <Container maxWidth="xl">
                    <Toolbar disableGutters>
                    <img src={logoMT} alt="logo" style={{marginRight : 30}}/>

                    <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
                        <IconButton
                        size="large"
                        aria-label="account of current user"
                        aria-controls="menu-appbar"
                        aria-haspopup="true"
                        onClick={handleOpenNavMenu}
                        color="inherit"
                        >
                        <MenuIcon />
                        </IconButton>
                        <Menu
                        id="menu-appbar"
                        anchorEl={anchorElNav}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'left',
                        }}
                        keepMounted
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'left',
                        }}
                        open={Boolean(anchorElNav)}
                        onClose={handleCloseNavMenu}
                        sx={{
                            display: { xs: 'block', md: 'none' },
                        }}
                        >
                            <MenuItem onClick={goToMap}>
                            <Typography textAlign="center">Mapa</Typography>
                            </MenuItem>
                            <MenuItem onClick={goToSensors}>
                            <Typography textAlign="center">Sensores</Typography>
                            </MenuItem>
                            <MenuItem onClick={goToOptions}>
                            <Typography textAlign="center">Opciones</Typography>
                            </MenuItem>
                        </Menu>
                    </Box>
                    <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
                        
                        <Button
                            onClick={goToMap}
                            sx={{ my: 2, color: 'white', display: 'block' }}
                        >
                            Mapa
                        </Button>
                        <Button
                            onClick={goToSensors}
                            sx={{ my: 2, color: 'white', display: 'block' }}
                        >
                            Sensores
                        </Button>
                        <Button
                            onClick={goToOptions}
                            sx={{ my: 2, color: 'white', display: 'block' }}
                        >
                            Opciones
                        </Button>
                        
                    </Box>

                    <Box sx={{ flexGrow: 0 }}>
                        <Tooltip title="Cerrar sesión">
                        <IconButton onClick={logout} sx={{ p: 0 }}>
                            <PowerSettingsNewIcon sx={{ color: 'white' }} fontSize="large"/>
                        </IconButton>
                        </Tooltip>
                        
                    </Box>
                    </Toolbar>
                </Container>
                </AppBar>
          );
      }
  }
  
  return (
    <>
    {drawAppBar()}
    </>   
  );
}
