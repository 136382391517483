import React, { useEffect, useState } from 'react';
import { MapContainer, TileLayer, useMap, Marker, Popup } from 'react-leaflet';
import '../leaflet.css'
import L from 'leaflet';
import { useNavigate } from 'react-router-dom';
import { SERVER_URL } from '../config.js';
import Link from '@mui/material/Link'
import Typography from '@mui/material/Typography'
import icon from 'leaflet/dist/images/marker-icon.png';
import iconShadow from 'leaflet/dist/images/marker-shadow.png';
import { ToastContainer, toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


let DefaultIcon = L.icon({
    iconUrl: icon,
    shadowUrl: iconShadow
});

L.Marker.prototype.options.icon = DefaultIcon;

export default function Map() {

    const navigate = useNavigate();
    const [sensors, setSensors] = useState([]);
    const [imgs,setImgs] = useState([]);
    const [map, setMap] = useState();
 
    useEffect(() => {
        fetchSensors();
      }, []);

     

    const fetchSensors = () => {
        const jwtToken = sessionStorage.getItem("jwt");
    
        //Use same endpoint as plot because are same params
        fetch(SERVER_URL + `sensors?unique=true`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': jwtToken
    
          }
        })
          .then((response) => response.json())
          .then((responseData => {
              console.log(responseData);
              //for(let i = 0; i < responseData.length; i++){
              //  fetchImage(responseData[i].id, i, responseData);
              //}
              
                setSensors(responseData);
              
              
              //for(let i = 0; i < responseData.length; i++){
              //  console.log(imgs[i]);
              //}
          }))
          .catch(err => console.error(err));
    }
    
    /*const fetchImage = (id, i) => {
        const jwtToken = sessionStorage.getItem("jwt");
        
        const response = fetch(SERVER_URL + `sensors/${id}/picture`,
            {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': jwtToken
                },
            })
            .then((response) => {
                if(response.ok){
                    return response.blob()
                }
                throw Error(response.statusText);
            })
            .then((blob) => {
      
                console.log("image retrieved for "+id);
                console.log("divImg")
                //document.getElementById("divImg").innerHTML = '';
                // 2. Create blob link to download
                const url = window.URL.createObjectURL(new Blob([blob]));
                const img = document.createElement('img');
                img.setAttribute("src", url);
                //img.setAttribute("height", "500");
                img.setAttribute("width", "100");
                img.setAttribute("alt", "image");
                
                let localImgs = imgs;
                localImgs[i] = url;
                setImgs(localImgs);
                // 3. Append to html page
                //document.getElementById("divImg").appendChild(img);
                //return(<div>caca</div>);
            })
        .catch (err => {
            console.error(err);
            toast.error("Error: "+ err.message);
        });
      }

      const renderImages = (index) => {
        return(
            <div id={`divImg${index}`}>
                <img src={imgs[index]} alt="icons2" width="100" />
            </div>
        );
      }*/

      

    const setPoints = () => {

        return(
            <div>
            {sensors.map((row, index) => {
                
                return(
                <Marker position={[Number(row.locationX), Number(row.locationY)]}>
                    <Popup>
                    <Typography>
                      <Link style={{cursor:'pointer'}} onClick={()=> {
                          navigate(`/sensors/${row.id}`)
                      }}>{row.code}</Link>
                        
                      </Typography> {row.ruta} <br /> {row.serial.substring(8)}
                    </Popup>
                </Marker>
            );})}
            </div>
        );
    }

    return(
        <div class="mapContainer">
        <MapContainer center={[43.11885, -3.96289]} zoom={10} scrollWheelZoom={true} whenCreated={setMap}>
            <TileLayer
                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            />
            {setPoints()}
        </MapContainer>
        </div>
    );
}