import React from 'react';

class Forbidden extends React.Component {
    render(){
        return(
            <div>
                <h1>Forbidden</h1>
                <h2>You are not allowed to enter here</h2>
            </div>
        )
    }
}

export default Forbidden;