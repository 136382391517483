import './App.css';
import React from 'react';
import {ThemeProvider} from '@mui/material/styles';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';

import { NotFound } from './components/Errors';
import Login from './components/Login';
import Forbidden from './Forbidden';
import PermissionsContext from './components/global/PermissionsContext.js'
import theme from './theme.js';
import { ToastContainer } from 'react-toastify';
import SensorList from './components/SensorList';
import Map from './components/Map.js';
import Sensor from './components/Sensor.js';
import ResponsiveAppBar from './components/ResponsiveAppBar';
import EditSensor from './components/EditSensor'

function App() {
  document.title = 'Naturea';
  /**const SecuredRoute = ({ component: Component,...rest}) =>
  (
    <Route {...rest} render={props => (
      sessionStorage.getItem("jwt") ? (
        <Component {...props}/>
      ) : (
        <Navigate to={{
          pathname: '/login',
          state: { from: props.location }
        }}/>
      )
    )}/>
  ) **/
  function PrivateRoute({ children }) {
    return sessionStorage.getItem("jwt") ? <>{children}</> : <Navigate to="/login" />;
  }
  

  //Permissions context
  const[permissions, setPermissions] = React.useState([false,false]);

  return (
    <div className="App">
      <PermissionsContext.Provider value = {{permissions, setPermissions}}>
            <BrowserRouter>
               <ThemeProvider theme={theme}> 
                  <ResponsiveAppBar/>
                  <Routes>
                    <Route exact path="/login" element={<Login/>} />
                    <Route exact path="/" element={<PrivateRoute><Map/></PrivateRoute>} />
                    <Route exact path="/sensors" element={<PrivateRoute><SensorList/></PrivateRoute>} />
                    <Route path="/sensors/:id" element={<PrivateRoute><Sensor/></PrivateRoute>} />
                    <Route path="/sensors/:id/edit" element={<PrivateRoute><EditSensor/></PrivateRoute>} />
                    <Route path="/403" element={Forbidden} />
                    <Route elemento={NotFound} />

                  </Routes>
 
               </ThemeProvider>
            </BrowserRouter>
        </PermissionsContext.Provider>
    </div>
  );
}

export default App;
